<template>
  <lab-task>
    <lab-gas-laws-part4 />
  </lab-task>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import LabGasLawsPart4 from '../simulations/LabGasLawsPart4.vue';
import LabTask from './labs/LabTask';

export default defineComponent({
  name: 'GasLawsPart4',
  components: {LabGasLawsPart4, LabTask},
  mixins: [DynamicQuestionMixin()],
});
</script>
